'use client'

import React, { useRef, useEffect, useState, useContext } from 'react'
import { useParams, usePathname } from 'next/navigation'
import dynamic from 'next/dynamic'

import { useFilters } from '@/context/FiltersContext'
import { ViewportContext } from '@/context/ViewportContext'
import { SearchLocationContext } from '@/context/SearchLocationContext'
import { useAuth } from '@/context/AuthContext'

import ActionButton from '@/components/ActionButton/ActionButton'
import InfoTooltip from '@/components/ui/InfoTooltip/InfoTooltip'
import Search from '@/components/Search/Search'

const UserMenu = dynamic(
  () => import('@/components/Layout/components/Header/components/UserMenu/UserMenu'),
  {
    ssr: false,
  },
)

const Kid = dynamic(() => import('@/components/Kid/Kid'), {
  ssr: false,
})

const AddKidModal = dynamic(() => import('@/components/AddKidModal/AddKidModal'), {
  ssr: false,
})

const FiltersModal = dynamic(() => import('@/components/Filters/Modal/FiltersModal'), {
  ssr: false,
})

import { useElementPosition } from '@/hooks/useElementPosition'

import useKids from '@/hooks/useKids'
import useModal from '@hooks/useModal'
import useViewport from '@/hooks/useViewport'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'
import hypenString from '@helpers/formatters/hyphenLowercaseString'

import { calculateAge } from '@helpers/calculateAge'

import IconArrows from '@/assets/icons/buttons/double-chevron-down.svg'
import IconFilter from '@/assets/icons/header/filter.svg'

import classes from './FixedHeader.module.scss'

const helperText =
  'You can add your children and their interests using the feature and instantly filter results to show child specific activities, events and articles.  You can use a nick name instead of a real name for your child.'

const FixedHeader = () => {
  const pathname = usePathname()
  const params = useParams()
  const isHomePage = pathname === '/'

  const filtersEnabled =
    pathname === `/${params?.stateId}/${params?.cityId}` ||
    pathname === `/${params?.stateId}/${params?.cityId}/activities`

  const { filters, setFilters, updateUrl, setIsOpen, isOpen } = useFilters()

  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })
  const { location: locationContext } = useContext(SearchLocationContext)

  const { user, setIsOpen: showAuth } = useAuth()
  const { kids, refetch: refetchKids } = useKids(user?.id || null)

  const headerRef = useRef<HTMLDivElement>(null)

  const { isOpen: isKidsModalOpen, toggle: toggleKidsModal } = useModal()
  // const isFixed = useElementFixed(headerRef)
  const { isOnTop: isFixed } = useElementPosition(headerRef)
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  const [pendingLogin, setPendingLogin] = useState(false)
  const [isSearchFocused, setSearchFocused] = useState(false)
  const [kidsBarIsOpen, setKidsBarIsOpen] = useState(false)

  const handleTooltipClose = () => setTooltipOpen(false)
  const handleTooltipOpen = () => setTooltipOpen(true)

  let rootClasses = classes.root

  if (isFixed) {
    rootClasses += ` ${classes.root_fixed}`
  }

  const handleAddKid = () => {
    trackGAEvent({
      action: 'click',
      category: 'Add Your Child',
      label: 'Fixed Header',
      data: `page_url: ${window.location.href}`,
    })

    if (!user?.id) {
      showAuth(true)
      setPendingLogin(true)
    } else {
      toggleKidsModal()
    }
  }

  useEffect(() => {
    if (user?.id && pendingLogin) {
      toggleKidsModal()
      setPendingLogin(false)
    }
  }, [user?.id, pendingLogin, toggleKidsModal])

  const toggleKidsBar = () => {
    setKidsBarIsOpen(!kidsBarIsOpen)
  }

  const handleCloseKids = async (refetch) => {
    if (refetch) {
      await refetchKids()
    }
    toggleKidsModal()
  }

  useEffect(() => {
    if (!isFixed) {
      setSearchFocused(false)
      // setKidsBarIsOpen(false)
    }
  }, [isFixed])

  let showKidsControls = !isSearchFocused
  let showUserMenu = isFixed && !isSearchFocused
  let showSearch = isFixed
  let showTooltip = !user?.id && !isFixed

  if (viewport !== 'mobile') {
    showKidsControls = true
    showUserMenu = true
    showSearch = true
    showTooltip = true
  }

  const ShowKids = ({ isActive, collapsed }: { isActive?: boolean; collapsed?: boolean }) => (
    <ActionButton
      onClick={toggleKidsBar}
      text="My Kids"
      Icon={IconArrows}
      theme={isFixed ? 'white' : undefined}
      collapsed={collapsed}
      isActive={isActive}
    />
  )

  const handleKidSet = ({ kid, checked }) => {
    setFilters((prevFilters) => {
      const normalizedActiveKids = prevFilters.activeKids.map((k) =>
        typeof k.uid !== 'undefined' && !k.birthdate && !k.interests && kids
          ? kids.find((kid) => kid.uid === k.uid)
          : k,
      )

      const newActiveKids = checked
        ? [...normalizedActiveKids, kid]
        : normalizedActiveKids.filter((k) => k?.uid !== kid.uid)

      const newAges = newActiveKids.map((k) => calculateAge(k.birthdate))
      const newExperiences = [...new Set(newActiveKids.flatMap((k) => k.interests))]

      const updatedFilters = {
        ...prevFilters,
        ages: newAges,
        experiences: newExperiences,
        activeKids: newActiveKids,
      }

      updateUrl({
        ...updatedFilters,
      })

      return updatedFilters
    })
  }

  const addKidButtonIsActive = !kids || kids?.length === 0 || kidsBarIsOpen

  const goToMyTownPage = () => {
    if (locationContext?.state_code && locationContext?.name) {
      window.open(
        `/${hypenString(locationContext.state_code)}/${hypenString(locationContext.name)}`,
        '_self',
      )
    }
    return null
  }

  const theme = isFixed ? 'light' : undefined

  return (
    <>
      <div className={rootClasses} ref={headerRef}>
        <div className={`${classes.row} container`}>
          <div className={classes.controls}>
            {showKidsControls && (
              <div className={classes.kids}>
                {addKidButtonIsActive || isHomePage ? (
                  <ActionButton
                    onClick={isHomePage ? goToMyTownPage : handleAddKid}
                    text="Add your child"
                    collapsed={viewport === 'mobile' && isFixed}
                    theme={isFixed ? 'white' : undefined}
                    Icon={'+'}
                  />
                ) : (
                  <ShowKids />
                )}
                {showTooltip && (
                  <div style={{ width: 20, height: 20 }}>
                    <InfoTooltip
                      title={helperText}
                      onClose={handleTooltipClose}
                      open={isTooltipOpen}
                      onOpen={handleTooltipOpen}
                      iconColor={isFixed ? '#AACFFF' : undefined}
                    />
                  </div>
                )}
              </div>
            )}
            {filtersEnabled && isFixed && (
              <div className={classes.filters}>
                <ActionButton
                  onClick={() => setIsOpen(!isOpen)}
                  text="Filters"
                  collapsed={viewport === 'mobile' && isFixed}
                  theme={isFixed ? 'white' : undefined}
                  Icon={IconFilter}
                />
              </div>
            )}
          </div>
          <div className={classes.search}>
            <Search
              collapsed={!showSearch}
              isOpen={isSearchFocused}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => setSearchFocused(false)}
              onOpen={() => setSearchFocused(true)}
              onClose={() => setSearchFocused(false)}
            />
          </div>
          {showUserMenu && (
            <div className={classes.user}>
              <UserMenu theme={theme} />
            </div>
          )}
        </div>
        {kidsBarIsOpen && (
          <div className={`${classes.row} container`}>
            <ShowKids isActive={true} collapsed={true} />
            <div className={classes.filterKids}>
              {kids &&
                kids.length &&
                kids.map((kid, i) => {
                  const isChecked = filters.activeKids.find((k) => k.uid === kid.uid)
                  return (
                    <Kid
                      key={i}
                      {...kid}
                      theme={theme}
                      onChange={handleKidSet}
                      checked={Boolean(isChecked)}
                    />
                  )
                })}
            </div>
          </div>
        )}
      </div>
      {user?.id && <AddKidModal isOpen={isKidsModalOpen} onClose={handleCloseKids} />}
      {filtersEnabled && <FiltersModal />}
    </>
  )
}

export default FixedHeader

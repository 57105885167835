import React, { createContext, useState, useContext, useEffect } from 'react'
import { Kid } from '../../types'
import { getFiltersFromUrlParams } from '@/utils/filters/getFiltersFromUrl'

export type Filters = {
  ages: number[]
  experiences: string[]
  activeKids: Kid[]
  withOnlineRegistration: boolean
  withReviews: boolean
  tags: any[]
  ageFrom: string
  ageTo: string
}

export type FiltersContextType = {
  filters: Filters
  initialFilters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  setInitialFilters: React.Dispatch<React.SetStateAction<Filters>>
  resetFilters: () => void
  updateUrl: (props: Filters) => void
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultFilters: Filters = {
  ages: [],
  experiences: [],
  activeKids: [],
  withReviews: false,
  withOnlineRegistration: false,
  tags: [],
  ageFrom: '',
  ageTo: '',
}

export const FiltersContext = createContext<FiltersContextType>({
  filters: defaultFilters,
  initialFilters: defaultFilters,
  setFilters: () => {},
  setInitialFilters: () => {},
  resetFilters: () => {},
  updateUrl: () => {},
  isOpen: false,
  setIsOpen: () => {},
})

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<Filters>(defaultFilters)
  const [initialFilters, setInitialFilters] = useState<Filters>(defaultFilters)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const urlParams = {
      ages: params.get('ages'),
      experiences: params.get('experiences'),
      activeKids: params.get('activeKids'),
      withOnlineRegistration: params.get('withOnlineRegistration'),
      withReviews: params.get('withReviews'),
      tags: params.get('tags'),
      ageFrom: params.get('ageFrom'),
      ageTo: params.get('ageTo'),
    }

    const filtersFromParams = getFiltersFromUrlParams(urlParams)

    const initialFilters: Filters = { ...filtersFromParams }
    setFilters(initialFilters)
    setInitialFilters(initialFilters)
  }, [])

  const updateUrl = (newFilters: Filters) => {
    const params = new URLSearchParams(window.location.search)

    if (newFilters.ages && newFilters.ages.length > 0) {
      params.set('ages', newFilters.ages.join(','))
    } else {
      params.delete('ages')
    }

    if (newFilters.experiences && newFilters.experiences.length > 0) {
      params.set('experiences', newFilters.experiences.join(','))
    } else {
      params.delete('experiences')
    }

    if (newFilters.activeKids && newFilters.activeKids.length > 0) {
      params.set('activeKids', newFilters.activeKids.map((k) => k.uid).join(','))
    } else {
      params.delete('activeKids')
    }

    if (newFilters.withOnlineRegistration) {
      params.set('withOnlineRegistration', 'true')
    } else {
      params.delete('withOnlineRegistration')
    }

    if (newFilters.withReviews) {
      params.set('withReviews', 'true')
    } else {
      params.delete('withReviews')
    }

    if (newFilters.tags && newFilters.tags.length > 0) {
      params.set('tags', newFilters.tags.join(','))
    } else {
      params.delete('tags')
    }

    if (newFilters.ageFrom) {
      params.set('ageFrom', newFilters.ageFrom)
    } else {
      params.delete('ageFrom')
    }

    if (newFilters.ageTo) {
      params.set('ageTo', newFilters.ageTo)
    } else {
      params.delete('ageTo')
    }

    window.history.pushState(null, '', `?${params.toString()}`)
  }

  const resetFilters = () => {
    setFilters(defaultFilters)
    updateUrl(defaultFilters)
  }

  return (
    <FiltersContext.Provider
      value={{
        filters,
        initialFilters,
        setFilters,
        setInitialFilters,
        resetFilters,
        updateUrl,
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export const useFilters = () => useContext(FiltersContext)

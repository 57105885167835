import React, { useEffect, useState } from 'react'

import classes from './ButtonsSlider.module.scss'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

type TButtonsSlider = {
  Items: Array<() => React.ReactElement>
  mobileGap?: string
  tabletGap?: string
  jsSlider?: boolean
}

export default function ButtonsSlider({
  Items,
  mobileGap = '27px',
  tabletGap = '34px',
  jsSlider,
}: TButtonsSlider) {
  const [useJsSlider, setUseJsSlider] = useState(false)
  let rootClasses = `${classes.root} ${classes.root_nojs}`

  useEffect(() => {
    const handleResize = () => {
      if (!jsSlider && window.innerWidth >= 1440) {
        setUseJsSlider(false)
      } else if (jsSlider) {
        setUseJsSlider(true)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [jsSlider])

  if (useJsSlider) {
    rootClasses = `${classes.root}`
  }

  const settings = {
    swipeToSlide: true, //mobile swipe
    draggable: true, //desktop mouse drag
    variableWidth: true,
    infinite: false,
    nav: false,
    dots: false,
  }

  return (
    <div
      className={rootClasses}
      style={
        {
          '--mobile-gap': mobileGap,
          '--tablet-gap': tabletGap,
        } as React.CSSProperties
      }
    >
      {useJsSlider ? (
        <Slider {...settings}>
          {Items.map((RenderItem, i) => (
            <div className={classes.item} key={i}>
              <RenderItem />
            </div>
          ))}
        </Slider>
      ) : (
        Items.map((RenderItem, i) => (
          <div className={classes.item} key={i}>
            <RenderItem />
          </div>
        ))
      )}
    </div>
  )
}
